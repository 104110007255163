import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_solutions.js";
import { Link } from "gatsby";
import { Image, Video } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p className="leading-relaxed text-lg">
  Reduce costs by maintaining a single source of data for the app, website and
  print products. Traffic management reduces maintenance costs of the
  infrastructure.
    </p>
    <br />
    <br />
    <h4>{`Single source of content`}</h4>
    <p>{`Maintain one single source of content only. From here the content on your
website, in the app, and in all QR-code connected print products is updated.`}</p>
    <Image src="solutions/singlesource.png" className="my-12 w-2/3 mx-auto" mdxType="Image" />
    <p>{`Digital content is easier to keep up to date than print. Print products that
are connected to digital data with QR-codes need less updates since the latest
information is always available.`}</p>
    <br />
    <p>{`Based on your content we can create high quality map print data. Website, app,
and print media have a uniform look. The presentation of your activities is
seamless.`}</p>
    <br />
    <div className="flex justify-center">
  <Video className="w-96" src="DipjSNZievI" style={{
        height: "17rem",
        paddingBottom: "0px"
      }} mdxType="Video" />
    </div>
    <br />
    <h4>{`Traffic management`}</h4>
    <p>{`By managing your traffic you can reduce the load on trails or focus the
traffic on robust trails. This will help reduce the wear and tear and lower
the maintenance cost.`}</p>
    <br />
    <p>{`React to exceptional conditions such as very wet weather snow or ice to prevent
trails from suffering too much during that time.`}</p>
    <br />
    <p>{`Extend your season! In spring and autumn, south facing trails (at least north
of equator) or in a lower altitude are often free of snow and very well
ridable. Make these ridable trails visible to your guests and close the others to
provide a good user experience and prevent your trails from beeing destroyed.`}</p>
    <Image src="solutions/maintenance.png" className="my-12 w-3/4 mx-auto" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      